import React, { useState } from "react";
import AuthService from "../service/AuthService";
import FormInput from "./UI/FormInput";
import Checkbox from "./UI/Checkbox";
import Button from "./UI/Button";
import ErrorMessage from "./UI/ErrorMessage";
import PasswordRecovery from "./PasswordRecovery";
import { useNotification } from "../context/NotificationContext";

export default function LoginForm({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(""); //P4ssw0rd!
  const [error, setError] = useState("");
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);
  const { addNotification } = useNotification();

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.login(email, password);
      addNotification("Login Success", "success");

      onLogin();
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const handlePasswordReset = () => {
    setIsPasswordRecovery(false);
    setEmail("");
    setPassword("");
  };

  const handleBackToLogin = () => {
    setIsPasswordRecovery(false);
  };

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {isPasswordRecovery ? "Password Recovery" : "Sign in to your account"}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
        {!isPasswordRecovery ? (
          <form className="space-y-2" onSubmit={handleLoginSubmit}>
            <FormInput
              label="Email address"
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
            <FormInput
              label="Password"
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Checkbox
              id="password-recovery"
              name="password-recovery"
              label="Password recovery"
              checked={isPasswordRecovery}
              onChange={() => setIsPasswordRecovery(!isPasswordRecovery)}
            />
            <ErrorMessage message={error} />
            <Button label="Sign in" />
          </form>
        ) : (
          <PasswordRecovery
            email={email}
            setEmail={setEmail}
            onPasswordReset={handlePasswordReset}
            onBackToLogin={handleBackToLogin} // Gestore del pulsante Indietro
          />
        )}
      </div>
    </div>
  );
}
