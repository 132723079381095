// src/components/lists/SchoolList.js
import React from "react";
import GenericList from "./GenericList";
import { useEditArtist } from "../../data/useEditArtist";
import { useDeleteArtist } from "../../data/useDeleteArtist";
import { useCreateArtist } from "../../data/useCreateArtist";
import { useArtists } from "../../data/useArtists";
import { useUsers } from "../../data/useUsers";

const ArtistFields = [
  { name: "name", label: "Name", required: true },
  { name: "artistInfo", label: "Artist Info", required: false },
];

const ArtistsList = () => {
  const { artists, error, isLoading } = useArtists();
  const { editArtist, isEditing } = useEditArtist();
  const { deleteArtist, isDeleting } = useDeleteArtist();
  const { createArtist, isCreating } = useCreateArtist();

  return (
    <GenericList
      items={artists}
      itemName="Artist"
      isLoading={isLoading || isDeleting}
      error={error}
      onEdit={(updatedItem) => editArtist(updatedItem)}
      onDelete={(id) => deleteArtist(id)}
      onCreate={(newItem) => createArtist(newItem)}
      fields={ArtistFields}
    />
  );
};

export default ArtistsList;
