// src/components/lists/GenericList.js
import React, { useState } from "react";
import ListItem from "./ListItem";
import LoadingSpinner from "../UI/LoadingSpinner";
import ItemForm from "../Form/ItemForm";
import ConfirmationModal from "../UI/ConfirmationModal";

const GenericList = ({
  items,
  isLoading,
  error,
  onEdit,
  onDelete,
  onCreate,
  fields,
  itemName,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleSaveNewItem = (newItem) => {
    onCreate(newItem);
    setIsAdding(false);
  };

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      onDelete(itemToDelete);
      setItemToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(null);
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <div>Error loading items</div>;

  return (
    <div className="">
      <div className="mx-auto w-fit pb-2">
        <button
          className="rounded border bg-white px-4 py-1"
          onClick={handleAddClick}
        >
          {`Insert New ${itemName}`}
        </button>
      </div>
      {isAdding && (
        <ItemForm
          defaultValues={fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: "" }),
            {},
          )}
          onSubmit={handleSaveNewItem}
          onCancel={handleCancelAdd}
          fields={fields}
        />
      )}
      {items.map((item) => (
        <ListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onDelete={(id) => setItemToDelete(id)}
          fields={fields}
        />
      ))}

      <ConfirmationModal
        isOpen={!!itemToDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want to delete this ${itemName}?`}
      />
    </div>
  );
};

export default GenericList;
