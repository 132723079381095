import { useQuery } from "@tanstack/react-query";
import AuthService from "../service/AuthService";

export function useRoles() {
  const {
    data: roles,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: AuthService.getRoles,
    cacheTime: 1000 * 60 * 60, // Mantieni i dati in cache per 60 minuti
    staleTime: 1000 * 60 * 15, // Mostra i dati in cache per 15 minuti

    error: (err) => {
      throw new Error(
        "Fatching data failed: " + err.message || "Network Error",
      );
    },
  });

  return {
    roles,
    isLoading,
    error,
  };
}
