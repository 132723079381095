import React, { useRef, useState } from "react";
import {
  FaFilePdf,
  FaFileWord,
  FaFileImage,
  FaFileAlt,
  FaFile,
  FaFileArchive,
} from "react-icons/fa";
import { useFileUpload } from "../hooks/useFileUpload";

export default function AttachmentUploadForm({ workId }) {
  const fileInputRef = useRef(null);

  const {
    selectedFile,
    fileName,
    handleFileSelect,
    onUploadClick,
    isUploading,
    error,
    resetFileInput,
  } = useFileUpload(workId);

  const handleFileUploadAndReset = async () => {
    console.log(selectedFile.name);
    await onUploadClick();
    resetFileInput();

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const getFileIcon = (file) => {
    if (!file) return <FaFile />;
    const fileType = file.type;

    if (fileType.includes("pdf"))
      return <FaFilePdf className="text-red-500" size={50} />;
    if (fileType.includes("word"))
      return <FaFileWord className="text-blue-500" size={50} />;
    if (fileType.includes("image"))
      return <FaFileImage className="text-green-500" size={50} />;
    if (fileType.includes("zip"))
      return <FaFileArchive className="text-amber-900" size={50} />;
    return <FaFileAlt className="text-gray-500" size={50} />;
  };

  return (
    <div className="flex flex-col items-center">
      {!selectedFile && (
        <>
          <input
            type="file"
            ref={fileInputRef}
            disabled={isUploading}
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          <button
            disabled={isUploading}
            onClick={(e) => {
              e.preventDefault(); // Previene il comportamento predefinito
              if (fileInputRef.current) {
                fileInputRef.current.click(); // Simula il click sull'input file
              }
            }}
            className="cursor-pointer rounded border border-gray-300 bg-slate-100 px-4 py-1 font-semibold hover:bg-slate-500 hover:text-white active:bg-slate-700"
          >
            {isUploading ? "Uploading..." : "New Attachment"}
          </button>
        </>
      )}
      {error && <p className="mt-2 text-red-500">{error}</p>}

      {selectedFile && (
        <div className="mx-8 flex items-center gap-2">
          {/* Mostra l'icona in base al tipo di file */}
          <div>{getFileIcon(selectedFile)}</div>
          {/* Mostra il nome del file */}
          <p className="mt-2 text-gray-700">{selectedFile.name}</p>
          <button
            type="button"
            onClick={resetFileInput}
            className="mx-2 mt-4 rounded bg-red-500 px-4 py-1 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleFileUploadAndReset}
            className={`mt-4 rounded px-4 py-1 text-white ${
              isUploading
                ? "cursor-not-allowed bg-gray-300"
                : "bg-green-500 hover:bg-green-700"
            }`}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
        </div>
      )}
    </div>
  );
}
