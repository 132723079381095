import { useQuery } from "@tanstack/react-query";
import SchoolService from "../service/SchoolService";

export function useSchools() {
  const {
    data: schools,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["schools"],
    queryFn: SchoolService.getAll,
    cacheTime: 1000 * 60 * 60, // Mantieni i dati in cache per 60 minuti
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: (data) => {
      localStorage.setItem("schoolsData", JSON.stringify(data));
    },
    initialData: () => {
      const savedData = localStorage.getItem("schoolsData");
      return savedData ? JSON.parse(savedData) : undefined;
    },
    error: (err) => {
      throw new Error(
        "Fatching data failed: " + err.message || "Network Error",
      );
    },
  });

  return {
    schools,
    isLoading,
    error,
  };
}
