import { useDeleteImage } from "../data/useDeleteImage";

const ThumbnailGallery = ({
  currentImageIndex,
  onThumbnailClick,
  isLoading = false,
  images,
  workId,
}) => {
  const { deleteImage } = useDeleteImage(workId);
  const handleDelete = async (imageUrl) => {
    try {
      await deleteImage(imageUrl);
      // Aggiorna la galleria di miniature per rimuovere l'immagine eliminata
    } catch (error) {
      console.error("Errore durante l'eliminazione dell'immagine:", error);
    }
  };

  return (
    <div className="flex justify-center mt-4 space-x-2">
      {isLoading && (
        <>
          <div className="animate-pulse bg-gray-200 h-16 w-16 rounded"></div>
          <div className="animate-pulse bg-gray-200 h-16 w-16 rounded"></div>
          <div className="animate-pulse bg-gray-200 h-16 w-16 rounded"></div>
        </>
      )}

      {images.map((image, index) => {
        return (
          !isLoading && (
            <div className="relative group w-32 h-32" key={index}>
              {index === 0 && (
                <i className="absolute text-yellow-200 top-2 left-2 fas fa-star"></i>
              )}
              <img
                key={index}
                src={image}
                alt=""
                className={`w-32 h-32 cursor-pointer border-2 ${
                  index === currentImageIndex
                    ? "border-blue-500"
                    : "border-transparent"
                }`}
                onClick={() => onThumbnailClick(index)}
              ></img>
              {index !== 0 && (
                <button
                  onClick={() => handleDelete(image)}
                  className=" absolute top-1 right-2 opacity-0 hover:opacity-100 text-red-500 hover:transition-all duration-300 hover:ease-in-out"
                  title="Elimina immagine"
                  disabled={isLoading}
                >
                  <i className="fas fa-trash fa-lg"></i>
                </button>
              )}
            </div>
          )
        );
      })}
    </div>
  );
};

export default ThumbnailGallery;
