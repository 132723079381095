import React, { useEffect, useRef } from "react";
//import { useNavigate } from "react-router-dom";
//import { useSearch } from "../component/SearchContext";

export default function Home() {
  //const searchInputRef = useRef(null);

  /*   useEffect(() => {
    // Imposta automaticamente il focus sull'input di ricerca quando la pagina viene caricata
    searchInputRef.current.focus();
  }, []); */

  return (
    <>
      {/*       // <Header onLogout={onLogout}></Header>
       */}
      <ContainerHome>
        {/*    <div className="flex flex-col h-56 justify-evenly items-center bg-gray-400 bg-opacity-20 backdrop-filter backdrop-blur-sm px-10 rounded-lg">
          <h1 className="text-3xl font-semibold racking-tight text-neutral-800">
            {`Welcome ${localStorage.getItem("name")}`}
          </h1>
          <SearchForm searchInputRef={searchInputRef} />
        </div> */}
      </ContainerHome>
    </>
  );
}

function ContainerHome({ children }) {
  return (
    <main
      className=" absolute top-0 w-screen -z-10 bg-cover flex justify-center items-center h-dvh"
      style={{ backgroundImage: "url('/img/img-cover.jpg')" }}
    >
      {children}
    </main>
  );
}

/* function SearchForm({ searchInputRef }) {
  const navigate = useNavigate();
  const { onSearchAll } = useSearch();

  const handleSubmit = (event) => {
    event.preventDefault();
    const searchTerm = searchInputRef.current.value.trim(); // Ottieni il termine di ricerca dall'input e rimuovi gli spazi bianchi in eccesso
    if (searchTerm) {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`); // Naviga alla pagina di ricerca con il termine di ricerca come parametro nell'URL
    } else {
      navigate("/search");
    }
    onSearchAll(searchTerm);
  };

  return (
    <>
      <form
        className="relative w-full flex flex-col items-center justify-center gap-6"
        onSubmit={handleSubmit}
      >
        <input
          ref={searchInputRef}
          className="block w-full rounded border-0 px-8 py-1.5 text-neutral-700 shadow-md ring-1 ring-inset ring-neutral-500 placeholder:text-neutral-400 focus-visible:outline-none sm:text-sm sm:leading-6"
          placeholder="Search all..."
        ></input>
        <i className="absolute left-2 top-2.5 text-gray-400 fas fa-search"></i>

        <button
          type="submit"
          className="flex w-4/12 justify-center rounded bg-neutral-800 px-3 py-1.5  text-sm font-semibold leading-6 text-neutral-100 shadow-md hover:bg-neutral-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-600"
        >
          Search
        </button>
      </form>
    </>
  );
} */
