// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SchoolService from "../service/SchoolService";
import { useNotification } from "../context/NotificationContext";

export function useCreateSchool() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: createSchool, isLoading: isCreating } = useMutation({
    mutationFn: async (newSchool) => {
      try {
        const response = await SchoolService.create(newSchool);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: async (data) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData = JSON.parse(localStorage.getItem("schoolsData")) || [];
      const updatedData = [...savedData, data].sort((a, b) =>
        a.description.localeCompare(b.description),
      );

      localStorage.setItem("schoolsData", JSON.stringify(updatedData));
      addNotification("School Created", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["schools"]);
    },

    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error adding school:", error);
    },
  });

  return { isCreating, createSchool };
}
