import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNotification } from "../context/NotificationContext";
import AttachmentService from "../service/AttachmentService";

export function useDeleteAttachment(workId) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: deleteAttachment, isLoading: isDeleting } = useMutation({
    mutationFn: (idAttachment) =>
      AttachmentService.deleteAttachment(idAttachment),

    onSuccess: async () => {
      console.log(workId);
      addNotification("Attachment deleted", "success");
      await queryClient.invalidateQueries(["work", workId]);
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error deleting attachment:", error);
    },
  });

  return { deleteAttachment, isDeleting };
}
