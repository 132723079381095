import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Server Error",
  nam_auth_6: "Token Expired",
  nam_attachment_1: "Attachment not found",
  default: "Generic Error",
};

const AttachmentService = {
  getAttachment: async (idAttachment) => {
    try {
      const resp = await makeAPIRequest({
        method: "get",
        url: "/attachments/get/" + idAttachment,
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      throw new Error(
        "Download Attachment failed: " +
          (error.errorCode === "nam_controller_0"
            ? error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  uploadAttachment: async ({ workId, file, fileName }) => {
    try {
      const formData = new FormData();
      formData.append("workId", workId);
      formData.append("file", file);
      formData.append("name", fileName);
      const resp = await makeAPIRequest({
        method: "post",
        url: "/attachments/upload",
        body: "formData",
        data: formData,
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Upload attachment failed: " +
          (error.errorCode === "nam_controller_0"
            ? error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  deleteAttachment: async (attachmentId) => {
    try {
      const resp = await makeAPIRequest({
        url: "/attachments/delete",
        method: "post",
        data: {
          request: attachmentId,
        },
      });

      return resp;
    } catch (error) {
      throw new Error(
        " Upload attachment failed: " +
          (error.errorCode === "nam_controller_0"
            ? error.errorMessage
            : ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default AttachmentService;
