import React, { useEffect, useState } from "react";

const UserItem = ({
  user,
  onEdit,
  onDelete,
  onResetPassword,
  fields,
  roles,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState(user["roles"] || []); // Stato per i ruoli selezionati

  useEffect(() => {
    setSelectedRoles(user["roles"] || []);
  }, [user]);

  const handleEditClick = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const handleSave = async (data) => {
    await onEdit({ userId: user.id, updatedRoles: selectedRoles });
    setIsEditing(false);
  };

  const handleReset = () => {
    onResetPassword(user.id);
  };

  const handleRoleChange = (role) => {
    // Aggiungi o rimuovi il ruolo dallo stato
    if (selectedRoles.includes(role)) {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    } else {
      setSelectedRoles([...selectedRoles, role]);
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-center border-b border-gray-200 py-2">
      <>
        <div className="flex flex-1 items-center py-4">
          {fields.map((field) => (
            <div key={field.name} className="h-fit flex-1 px-4 text-center">
              {Array.isArray(user[field.name]) && !isEditing
                ? user[field.name].map((subItem, index) => (
                    <p key={index}>{subItem}</p>
                  ))
                : Array.isArray(user[field.name])
                  ? roles?.map((option) => (
                      <div key={option} className="flex items-center">
                        <input
                          type="checkbox"
                          id={`${option}`}
                          value={option}
                          className="rounded border border-gray-300 p-2"
                          checked={selectedRoles.includes(option)}
                          onChange={() => handleRoleChange(option)}
                        />
                        <label htmlFor={`${option}`} className="ml-2">
                          {option}
                        </label>
                      </div>
                    ))
                  : user[field.name]}
            </div>
          ))}
        </div>
        <div className="flex space-x-2">
          <button
            onClick={handleReset}
            className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
          >
            Reset password
          </button>
          {!isEditing ? (
            <button
              onClick={handleEditClick}
              className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
            >
              Edit Roles
            </button>
          ) : (
            <button
              onClick={handleSave}
              className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-700"
            >
              Save Roles
            </button>
          )}
          <button
            onClick={() => onDelete(user.id)}
            className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </>
    </div>
  );
};

export default UserItem;
