import React from "react";
import UsersList from "../component/List/UsersList";

export function Users() {
  return (
    <div className="mx-auto w-full max-w-screen-lg pt-24">
      <div className="divide-gray-20 divide-y">
        <UsersList />
      </div>
    </div>
  );
}
