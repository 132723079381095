import React from "react";
import ArtistList from "../component/List/ArtistsList";
import UsersList from "../component/List/UsersList";

export function Artists() {
  return (
    <div className="mx-auto w-full max-w-screen-lg pt-24">
      <div className="divide-gray-20 divide-y">
        <ArtistList />
      </div>
    </div>
  );
}
