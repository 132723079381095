import { useCallback, useState } from "react";
import { useUploadAttachment } from "../data/useUploadAttachment";

export function useFileUpload(workId) {
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  console.log(fileName);
  const { uploadAttachment, isUploading } = useUploadAttachment({
    workId,
  });

  const handleFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
  }, []);

  const onUploadClick = useCallback(async () => {
    setError(null);
    try {
      await uploadAttachment(selectedFile);
    } catch (err) {
      setError("Error uploading file");
    } finally {
      setSelectedFile(null);
      setFileName("");
    }
  }, [uploadAttachment, selectedFile]);

  const resetFileInput = useCallback(() => {
    setSelectedFile(null);
    setFileName("");
    setError(null);
  }, []);

  return {
    selectedFile,
    fileName,
    handleFileSelect,
    onUploadClick,
    isUploading,
    error,
    resetFileInput,
  };
}
