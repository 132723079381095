import React from "react";
import { ActiveSliderImage } from "./ActiveSliderImage";

const ImageSlider = ({
  image,
  isLoading = false,
  error = false,
  isTransitioning,
  isPrimary,
}) => {
  return (
    <div className="max-w-screen-lg">
      {isLoading && (
        <div className="mx-auto flex h-64 w-64 items-center justify-center rounded bg-gray-200">
          <div className="flex h-full w-full animate-pulse flex-col items-center justify-center rounded bg-gray-300">
            <div className="animate-pulse">Loading...</div>
          </div>
        </div>
      )}
      {!isLoading && image && (
        <ActiveSliderImage
          src={image}
          isTransitioning={isTransitioning}
          isPrimary={isPrimary}
        />
      )}
      {((!isLoading && !image) || error) && (
        <div className="mx-auto flex h-64 w-64 items-center justify-center bg-gray-300">
          No Image Found
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
