import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Server Error",
  nam_auth_6: "Token Expired",
  default: "Generic Error",
};

const ArtistService = {
  getAll: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/artists/gets",
      });
      const sortedArtist = [...resp.artists].sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      return sortedArtist;
    } catch (error) {
      console.log(error);
      throw new Error(
        "Fetching All Artists failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },

  get: async (id) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: `/artists/get/${id}`,
      });

      return resp.artist;
    } catch (error) {
      throw new Error(
        "Fetching Artist" +
          id +
          " failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
  create: async (artistData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/artists/create",
        data: artistData,
      });

      return resp.artist;
    } catch (error) {
      throw new Error(
        "Creating artist failed: " +
          (ERROR_MESSAGES[error.message.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
  delete: async (artistId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/artists/delete",
        data: {
          request: artistId,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Deleting artist failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },

  update: async (artistData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/artists/update",
        data: artistData,
      });
      return resp.artist;
    } catch (error) {
      throw new Error(
        "Update exhibition failed: " +
          (ERROR_MESSAGES[error.message] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default ArtistService;
