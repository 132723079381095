import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useNotification } from "../context/NotificationContext";
import AttachmentService from "../service/AttachmentService";

export function useUploadAttachment({ workId }) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const uploadAttachmentMutation = useMutation(
    async (file) => {
      console.log(file.name);
      const newAttachmentId = await AttachmentService.uploadAttachment({
        workId,
        file,
        fileName: file.name,
      });
      return newAttachmentId;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["work", workId]);
        addNotification("Attachment uploaded", "success");
      },
      onError: (error) => {
        // Gestisci l'errore
        addNotification(error.message, "error");
        console.error("Error uploading image:", error);
      },
    },
  );

  return {
    uploadAttachment: uploadAttachmentMutation.mutateAsync,
    isUploading: uploadAttachmentMutation.isLoading,
  };
}
