import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-modal";

import { useArtists } from "../data/useArtists";
import { useSchools } from "../data/useSchools";
import { useExhibitions } from "../data/useExhibitions";
import { useCreateWork } from "../data/useCreateWork";
import { useNavigate } from "react-router-dom";

export default function AddWorkModal({ isOpen, onRequestClose }) {
  const { artists, isLoading: artistLoading } = useArtists();
  const { schools, isLoading: schoolsLoading } = useSchools();
  const { exhibitions, isLoading: exhibitionLoading } = useExhibitions();
  const { createWork, isCreating } = useCreateWork(); // Usa il hook per la mutazione
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      artistId: "",
      schoolId: "",
      exhibitionIds: [],
    },
  });

  const selectedArtist = watch("artistId");

  const handleFormSubmit = (data) => {
    createWork(data, { onSuccess: (resp) => navigate(`/work/${resp.id}`) }); // Invoca la mutazione per creare il nuovo lavoro
  };

  const handleClose = () => {
    reset();
    onRequestClose();
  };

  if (artistLoading || schoolsLoading || exhibitionLoading) {
    return null;
  }

  return (
    <>
      <Modal
        style={{
          content: {
            marginTop: "18px",
            border: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            padding: "0",
          },
        }}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <form
          className="mb-24 flex flex-col items-center"
          onSubmit={handleSubmit(handleFormSubmit)}
          autoComplete="off"
        >
          <div className="flex gap-4">
            <button
              className="mr-4 mt-8 w-24 rounded border border-gray-500 bg-red-300 py-1 font-semibold text-gray-600 hover:bg-red-500 hover:text-gray-200"
              type="button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className={`mt-8 w-24 rounded border border-gray-500 bg-green-300 py-1 font-semibold text-gray-600 hover:bg-green-600 hover:text-gray-200`}
              type="submit"
            >
              Add Work
            </button>
          </div>
          <div className="mx-auto mt-6 grid w-screen max-w-screen-lg grid-flow-row justify-center rounded-lg bg-white p-6 drop-shadow-2xl md:grid-cols-2 md:gap-4">
            <div className="flex items-center justify-start gap-4 text-lg tracking-tight">
              <span className="font-semibold text-stone-600">Catalogue: </span>

              <input
                {...register("catalogue", {
                  required: "Catalogue is required",
                })}
                className={`border p-1 ${errors.catalogue ? "border-red-800 focus:outline-none" : ""}`}
              />
              {errors.catalogue && (
                <span className="text-base text-red-800">
                  {errors.catalogue.message}
                </span>
              )}
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Location: </span>
              <input
                {...register("location")}
                className="flex-grow border p-1"
              />
            </div>

            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Artist: </span>

              <select
                {...register("artistId", {
                  required: "Artist is required",
                })}
                defaultValue={""}
                className={`w-full flex-grow border p-1 ${errors.artistId ? "border-red-800 focus:outline-none" : ""}`}
              >
                {artists?.map((artist) => (
                  <option value={artist.id} key={artist.id}>
                    {artist.name}
                  </option>
                ))}
              </select>
              {errors?.artistId && (
                <span className="flex-shrink-0 text-base text-red-800">
                  {errors.artistId.message}
                </span>
              )}
            </div>

            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="flex-shrink-0 font-semibold">Artist Info: </span>

              <input
                name="artistInfo"
                value={
                  artists?.find((artist) => artist.id === selectedArtist)
                    ?.artistInfo || ""
                }
                className="flex-grow overflow-x-visible border p-1"
                disabled={true}
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Title: </span>

              <input
                {...register("title", { required: "Title is required" })}
                className={`flex-grow overflow-x-visible border p-1 ${errors.title ? "border-red-800 focus:outline-none" : ""}`}
              />

              {errors?.title && (
                <span className="text-base text-red-800">
                  {errors?.title?.message}
                </span>
              )}
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Title En: </span>

              <input
                {...register("titleEn")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">School: </span>

              <select
                {...register("schoolId")}
                className="w-full flex-grow border p-1"
              >
                {schools?.map((school) => (
                  <option value={school.id} key={school.id}>
                    {school.description}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Attribution: </span>

              <input
                {...register("attribution")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Dimension: </span>

              <input
                {...register("dimension")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Medium:</span>

              <input
                {...register("medium")}
                className="flex-grow overflow-x-visible border p-1"
                ì
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Frame:</span>

              <input
                {...register("frame")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Condition:</span>

              <input
                {...register("condition")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600 md:col-span-2">
              <span className="font-semibold">Literature:</span>
              <textarea
                {...register("literature")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex flex-col text-lg tracking-tight text-stone-600 md:col-span-2">
              <span className="flex-shrink-0 font-semibold">
                Past Exhibitions:
              </span>
              <textarea
                {...register("pastExhibition")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>

            <div className="text-lg font-semibold tracking-tight text-stone-600 md:col-span-2">
              <span>Exhibitions:</span>

              <div className="mt-2 h-40 overflow-y-scroll border p-2">
                {exhibitions?.map((exhibition) => (
                  <div key={exhibition.id} className="mb-2">
                    <Controller
                      name="exhibitionIds"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          type="checkbox"
                          id={exhibition.id}
                          value={exhibition.id}
                          checked={value.includes(exhibition.id)}
                          onChange={(e) => {
                            const selectedIds = e.target.checked
                              ? [...value, e.target.value]
                              : value.filter((id) => id !== e.target.value);
                            onChange(selectedIds);
                          }}
                        />
                      )}
                    />
                    <label htmlFor={exhibition?.id} className="ml-2">
                      {exhibition?.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-span-2 flex justify-start"></div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Ownership:</span>

              <input
                {...register("ownership")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Temporary Location:</span>
              <input
                {...register("temporaryLocation")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Provenance:</span>

              <input
                {...register("provenance")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Destination:</span>
              <input
                {...register("destination")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Past Notes:</span>
              <input
                {...register("pastNote")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>

            <div className="flex items-center gap-4 text-lg tracking-tight text-stone-600">
              <span className="font-semibold">Future Notes:</span>
              <input
                {...register("futureNote")}
                className="flex-grow overflow-x-visible border p-1"
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}
