// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AuthService from "../service/AuthService"; // Adatta il percorso in base alla tua struttura
import { useNotification } from "../context/NotificationContext";

export function useCreateUser() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: createUser, isLoading: isCreating } = useMutation({
    mutationFn: async (newUser) => {
      try {
        const response = await AuthService.createUserWithRole(newUser);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        console.error(error); // Log degli errori
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: (newWork) => {
      // Invalida e refetch dei dati
      addNotification("User created", "success");
      queryClient.invalidateQueries(["users"]);
    },

    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
    },
  });

  return { isCreating, createUser };
}
