import { useQueryClient, useMutation } from "@tanstack/react-query";
import SchoolService from "../service/SchoolService";
import { useNotification } from "../context/NotificationContext";

export function useEditSchool() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: editSchool, isLoading: isEditing } = useMutation({
    mutationFn: async (schoolData) => {
      return await SchoolService.update(schoolData); // Ritorna i dati aggiornati
    },

    onSuccess: async (updatedSchool) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData = JSON.parse(localStorage.getItem("schoolsData")) || [];
      const updatedData = savedData?.map((school) =>
        school.id === updatedSchool.id ? updatedSchool : school,
      );
      localStorage.setItem("schoolsData", JSON.stringify(updatedData));
      addNotification("School updated", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["schools"]);
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error editing school:", error);
    },
  });

  return { editSchool, isEditing };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
