import { useQuery } from "@tanstack/react-query";
import AuthService from "../service/AuthService";
import { useNotification } from "../context/NotificationContext";

export function useUsers() {
  const { addNotification } = useNotification();

  const {
    data: users,
    isFetching,
    error,
  } = useQuery({
    queryKey: ["users"],
    queryFn: AuthService.getActiveUsers,
    cacheTime: 1000 * 60 * 60, // Mantieni i dati in cache per 60 minuti
    refetchOnMount: true,
    refetchOnWindowFocus: true,

    onSuccess: (data) => {
      localStorage.setItem("usersData", JSON.stringify(data));
    },
    initialData: () => {
      const savedData = localStorage.getItem("usersData");
      return savedData ? JSON.parse(savedData) : undefined;
    },
    onError: (error) => {
      // Gestisci l'errore
      console.log(error);
      addNotification(error.message, "error");
    },
  });

  return {
    users,
    isLoading: isFetching,
    error,
  };
}
