import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useUploadImage } from "../data/useUploadImage";
import { useParams } from "react-router-dom";

export function ActiveSliderImage({ src, isTransitioning, isPrimary }) {
  const id = useParams().id;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [error, setError] = useState(null);
  const { uploadImage, isUploading } = useUploadImage({
    image: selectedFile,
    primary: isPrimary,
    workId: id,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" || event.key === "Esc") {
        setMenuIsOpen(false);
      }
    };
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target !== document.querySelector(".active-slider-image")) {
        setMenuIsOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleImageClick = (event) => {
    event.preventDefault();
    const mouseX = event.clientX;
    const mouseY = event.clientY + window.scrollY; // Aggiunge lo scrollY per calcolare la posizione assoluta del mouse
    console.log(event.target);
    setMenuIsOpen((menuIsOpen) => !menuIsOpen);
    setMenuPosition({ x: mouseX, y: mouseY });
  };

  const handleCloseMenu = () => {
    setMenuIsOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangePrimary = () => {
    setMenuIsOpen(false);
    // Apri la finestra di selezione del file
    document.getElementById("fileInput").click();
  };

  const handleFullScreen = () => {
    const img = new Image();
    img.src = src;
    img.style.width = "100vw";
    img.style.height = "100vh";
    img.style.objectFit = "contain";
    const openImage = window.open("");
    openImage.document.write(img.outerHTML);
    setMenuIsOpen(false);
  };

  const handleCloseModal = () => {
    setPreview(null);
    document.getElementById("fileInput").value = null;
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "download";
    link.click();
    setMenuIsOpen(false);
  };

  const onUploadClick = async () => {
    try {
      console.log("Uploading image...");
      await uploadImage();
      setPreview(null);
      document.getElementById("fileInput").value = null;
    } catch (error) {
      setError("Error uploading image", error);
    }
  };

  return (
    <div className="relative">
      <input
        type="file"
        accept="image/*"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />

      {preview && (
        <Modal
          style={{
            content: {
              width: "90vw",
              height: "95vh",
              margin: "0",
              inset: "5vw",
              padding: "0",
            },
          }}
          isOpen={!!preview}
          onRequestClose={handleCloseModal}
          contentLabel="Example Modal"
        >
          <button
            className="absolute right-5 top-5 rounded border px-2 py-1 text-black"
            onClick={handleCloseModal}
          >
            Close
          </button>
          <div className="mt-6 flex flex-col items-center justify-start gap-2 overflow-hidden p-10">
            <div className="h-5/6">
              <img
                src={preview}
                alt="Preview"
                className="m-auto block max-h-full w-auto object-contain"
              />
            </div>
            <button
              type="button"
              onClick={onUploadClick}
              className={`mt-4 rounded px-4 py-2 text-white ${
                isUploading
                  ? "cursor-not-allowed bg-gray-300"
                  : "bg-green-500 hover:bg-green-700"
              }`}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </Modal>
      )}

      <img
        src={src}
        alt="Gallery"
        onClick={handleImageClick}
        className={`m-auto h-auto w-full transition-opacity duration-500 ease-in-out ${
          isTransitioning ? "opacity-0" : "active-slider-image opacity-100"
        }`}
      />
      <Modal
        isOpen={menuIsOpen}
        onRequestClose={handleCloseMenu}
        className="image-modal"
        overlayClassName="image-modal-overlay"
        style={{
          content: {
            top: `${menuPosition.y}px`,
            left: `${menuPosition.x}px`,
            right: "auto",
            bottom: "auto",
            border: "none",
            background: "transparent",
            padding: "0",
            zIndex: 9999, // Assicura che il modal sia sopra tutto il resto
          },
          overlay: {
            zIndex: 9998, // Assicura che l'overlay sia sopra tutti gli altri elementi
          },
        }}
      >
        <ul
          className="menu absolute cursor-pointer rounded bg-white p-2 text-black"
          style={{ top: menuPosition.y, left: menuPosition.x }}
        >
          <li
            className="rounded p-1 px-2 hover:bg-slate-300"
            onClick={handleFullScreen}
          >
            Open full screen
          </li>
          <li
            className="rounded p-1 px-2 hover:bg-slate-300"
            onClick={handleDownload}
          >
            Download Image
          </li>
          {isPrimary && (
            <li
              className="rounded p-1 px-2 hover:bg-slate-300"
              onClick={handleChangePrimary}
            >
              Change Image
            </li>
          )}
        </ul>
      </Modal>
    </div>
  );
}
