import { useNavigate } from "react-router-dom";
import { useSearch } from "../context/SearchContext";

export function useNavigationSearch(id, filteredData) {
  const navigate = useNavigate();
  const { currentPage } = useSearch();

  const currSearchIndex = filteredData?.findIndex((work) => work.id === id);
  const searchFlow = currSearchIndex !== -1;
  const searchStart = currSearchIndex === 0;
  const searchEnd = currSearchIndex === filteredData.length - 1;

  const handleClickBack = () => {
    navigate(`/search/?page=${currentPage + 1}`);
  };

  const handleClickSearchPrev = () => {
    const prevSearchLink = filteredData[currSearchIndex - 1]?.id;
    navigate(`/work/${prevSearchLink}`);
  };

  const handleClickSearchNext = () => {
    const nextSearchLink = filteredData[currSearchIndex + 1]?.id;
    navigate(`/work/${nextSearchLink}`);
  };

  const handleClickPrevSearchedItem = () => {
    const prevSearchLink = filteredData[currSearchIndex]?.id;
    navigate(`/work/${prevSearchLink}`);
  };

  return {
    searchFlow,
    searchEnd,
    searchStart,
    handleClickBack,
    handleClickSearchPrev,
    handleClickSearchNext,
    handleClickPrevSearchedItem,
  };
}
