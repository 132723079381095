import React from "react";
import SchoolList from "../component/List/SchoolsList";

export function Schools() {
  return (
    <div className="mx-auto w-full max-w-screen-md pt-24">
      <div className="divide-gray-20 divide-y">
        <SchoolList />
      </div>
    </div>
  );
}
