import { useQueryClient, useMutation } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";
import { useNotification } from "../context/NotificationContext";

export function useDeleteImage(workId) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const deleteImageMutation = useMutation(
    async (imageUrl) => {
      const imageId = queryClient
        .getQueryData(["secondaryImages", workId])
        .find((image) => image.url === imageUrl).id;
      console.log("useDeleteImage", imageId);
      await ImagesService.delete(imageId);
    },
    {
      onSuccess: async () => {
        addNotification("Image deleted", "success");
        await queryClient.invalidateQueries(["work", workId]);
        await queryClient.invalidateQueries(["secondaryImages", workId]);
      },

      onError: (error) => {
        // Gestisci l'errore
        addNotification(error.message, "error");
        console.error("Error deleting image:", error);
      },
    },
  );

  return {
    deleteImage: deleteImageMutation.mutateAsync,
    isDeleting: deleteImageMutation.isLoading,
  };
}
