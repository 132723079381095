// src/components/lists/SchoolList.js
import React, { useState } from "react";
import { useUsers } from "../../data/useUsers";
import LoadingSpinner from "../UI/LoadingSpinner";
import ConfirmationModal from "../UI/ConfirmationModal";
import UserForm from "../Form/UserForm";
import UserItem from "./UserItem";
import { useEditRoles } from "../../data/useEditRoles";
import { useRoles } from "../../data/useRoles";
import { useCreateUser } from "../../data/useCreateUser";
import { useDeleteUser } from "../../data/useDeleteUser";
import AuthService from "../../service/AuthService";
import { useNotification } from "../../context/NotificationContext";

const usersField = [
  { name: "name", label: "Name", required: true },
  { name: "lastname", label: "Lastname", required: true },
  { name: "email", label: "Email", required: true },
  { name: "roles", label: "Roles", required: true },
];

const UsersList = () => {
  const { roles, error: errorRoles, isLoading: isLoadingRoles } = useRoles();
  const { users, error, isLoading } = useUsers();
  const { createUser, isCreating } = useCreateUser();
  const { editRoles, isEditing } = useEditRoles();
  const { deleteUser, isDeleting } = useDeleteUser();
  const { addNotification } = useNotification();

  const [isAdding, setIsAdding] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToReset, setUserToReset] = useState(null);

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelAdd = () => {
    setIsAdding(false);
  };

  const handleSaveNewUser = (newUser) => {
    createUser(newUser);
    setIsAdding(false);
  };

  const handleConfirmDelete = () => {
    if (userToDelete) {
      deleteUser(userToDelete);
      setUserToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setUserToDelete(null);
  };

  const handleConfirmReset = async () => {
    if (userToReset) {
      try {
        await AuthService.resetPassword(userToReset);
        addNotification("Reset Passwor Completed", "success");
      } catch (error) {
        addNotification(error, "error");
      }
      setUserToReset(null);
    }
  };

  const handleCancelReset = () => {
    setUserToReset(null);
  };

  if (isLoadingRoles || isEditing || isLoading || isDeleting)
    return <LoadingSpinner />;

  if (error) return <div>Error loading items</div>;

  return (
    <div className="">
      <div className="mx-auto w-fit pb-2">
        <button
          className="rounded border bg-white px-4 py-1"
          onClick={handleAddClick}
        >
          {`Insert New User`}
        </button>
      </div>
      {(isAdding || isCreating) && (
        <UserForm
          defaultValues={usersField
            .filter((field) => field.name !== "roles") // Filtra "roles" fuori dall'array
            .reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})}
          onSubmit={handleSaveNewUser}
          onCancel={handleCancelAdd}
          fields={usersField}
          roles={roles}
        />
      )}
      {!(isLoadingRoles || isEditing || isLoading || isDeleting) &&
        users.map((user) => (
          <UserItem
            key={user.id}
            user={user}
            onEdit={editRoles}
            onDelete={(id) => setUserToDelete(id)}
            onResetPassword={(id) => setUserToReset(id)}
            fields={usersField}
            roles={roles}
          />
        ))}

      <ConfirmationModal
        isOpen={!!userToDelete}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        message={`Are you sure you want to delete this User?`}
      />

      <ConfirmationModal
        isOpen={!!userToReset}
        onConfirm={handleConfirmReset}
        onCancel={handleCancelReset}
        message={`Are you sure you want reset password?`}
      />
    </div>
  );
};

export default UsersList;
