import { useQueryClient, useMutation } from "@tanstack/react-query";
import AuthService from "../service/AuthService";
import { useNotification } from "../context/NotificationContext";

export function useDeleteUser() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: deleteUser, isLoading: isDeleting } = useMutation({
    mutationFn: (idUser) => AuthService.deleteUser(idUser),

    onSuccess: () => {
      addNotification("User deleted", "success");
      queryClient.invalidateQueries(["users"]);
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error deleting artist:", error);
    },
  });

  return { deleteUser, isDeleting };
}
