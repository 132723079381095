import axios from "axios";
import AuthService from "../AuthService";

const API_URL = process.env.REACT_APP_API_URL;

const makeAPIRequest = async (options) => {
  const { method, url, data, responseType } = options;
  try {
    const response = await axios({
      method,
      url: API_URL + url,
      data,
      responseType,
      headers: { Authorization: `${localStorage.getItem("token")}` },
    });

    return response.data;
  } catch (error) {
    if (!error.response) {
      console.error("Network Error:", error.message);
      throw new Error("Network Error: Please check your internet connection.");
    }

    const errorData = await parseBlobToJsonIfNeeded(error).catch(
      () => error.response?.data,
    );

    if (
      error.response?.status === 401 &&
      errorData?.errorCode === "nam_auth_6"
    ) {
      try {
        await AuthService.refreshToken();
        return makeAPIRequest(options);
      } catch (refreshError) {
        throw Error(
          refreshError.response
            ? refreshError.response.data.errorCode
            : refreshError,
        );
      }
    } else {
      throw error.response?.data;
    }
  }
};

export default makeAPIRequest;

const parseBlobToJsonIfNeeded = async (error) => {
  if (error.response && error.response.data instanceof Blob) {
    try {
      const errorText = await error.response.data.text();
      return JSON.parse(errorText);
    } catch {
      throw new Error("Non interpretabile");
    }
  }
  throw error; // Se non è un blob, rilancia l'errore originale
};
