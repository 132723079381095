import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageGallery from "../component/ImageGallery";
import NavigationSearch from "../component/SearchNavigation";
import WorkInfo from "../component/WorkInfo";

export default function WorkDetail() {
  const { id } = useParams();

  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    setShowInfo(false);
    const timeout = setTimeout(() => setShowInfo(true), 150); // Mostra le info dopo 75ms
    return () => clearTimeout(timeout);
  }, [id]);

  return (
    <div className="relative mx-4 flex max-w-screen-2xl flex-col items-center gap-4 p-4 pt-24 2xl:mx-auto">
      <NavigationSearch id={id} />
      {<ImageGallery workId={id} />}
      {showInfo && <WorkInfo id={id} />}
    </div>
  );
}
