import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { useImage } from "../data/useImage";

const PrintItem = ({ item, image }) => {
  return (
    <div
      style={{
        pageBreakAfter: "always",
        pageBreakInside: "avoid",
      }}
      className="m-10 text-center text-sm"
    >
      {image && (
        <img
          style={{
            maxHeight: `calc(100vh - 150px)`, // Utilizza template literals per inserire calc
          }}
          className="mx-auto mb-14 max-w-full object-contain"
          src={image}
          alt={item.title}
        />
      )}
      {item && (
        <p className="text-lg">
          {item.catalogue}
          {item.artist ? ` - ${item.artist.name}` : ""}
          {item.title ? ` - ${item.title}` : ""}
          {item.medium ? ` - ${item.medium}` : ""}
          {item.dimension ? ` - ${item.dimension}` : ""}
        </p>
      )}
    </div>
  );
};

export default PrintItem;
