import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintItem from "./PrintItem";
import { useQueryClient } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";

const PrintWorks = ({ itemsToPrint, text }) => {
  const printRef = useRef(null);
  const queryClient = useQueryClient();
  const [isPrinting, setIsPrinting] = useState(null);
  const [images, setImages] = useState({});

  const fetchImage = async (photoId) => {
    const image = await queryClient.fetchQuery(
      ["images", photoId],
      async () => {
        const blob = await ImagesService.getJpg(photoId);
        return URL.createObjectURL(blob);
      },
    );
    return image;
  };

  const preparePrint = async () => {
    setIsPrinting(true);
    try {
      const fetchPromises = itemsToPrint.map(async (item) => {
        const photoId = item?.photoIds?.find((photo) => photo.primary)?.id;
        if (photoId) {
          const imageUrl = await fetchImage(photoId);
          return { itemId: item.id, imageUrl };
        }
        return Promise.resolve({ itemId: item?.id, imageUrl: null });
      });

      const fetchedImages = await Promise.all(fetchPromises);
      const imagesMap = await fetchedImages.reduce((acc, curr) => {
        if (curr) {
          acc[curr.itemId] = curr.imageUrl;
        }
        return acc;
      }, {});

      setImages(imagesMap);
    } catch (e) {
      console.error(e);
    } finally {
      setIsPrinting(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrintClick = () => {
    handlePrint();
  };

  useEffect(() => {
    preparePrint();
  }, [itemsToPrint]);

  /*  useEffect(() => {
    if (
      !isPrinting &&
      itemsToPrint?.length === Object.keys(images).length &&
      itemsToPrint?.length > 0
    ) {
      handlePrint();
    }
  }, [isPrinting, handlePrint, itemsToPrint, images]);
 */
  return (
    <div>
      <button
        className="relative rounded border border-gray-300 bg-slate-100 p-1 px-8 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-300 disabled:text-white disabled:opacity-50"
        onClick={handlePrintClick}
        disabled={isPrinting}
      >
        {text}
        {isPrinting && ( // Mostra un indicatore di caricamento se l'immagine è in fase di recupero
          <div className="absolute -right-1 top-2 mr-4 flex h-4 w-4 items-center justify-center rounded-md">
            <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
          </div>
        )}
      </button>
      <div ref={printRef} className="hidden print:block">
        {itemsToPrint?.map((item, index) => (
          <PrintItem key={index} item={item} image={images[item?.id]} />
        ))}
      </div>
    </div>
  );
};

export default PrintWorks;
