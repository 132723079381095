import React from "react";
import ExhibitionList from "../component/List/ExhibitionsList";

export function Exhibitions() {
  return (
    <div className="mx-auto w-full max-w-screen-lg pt-24">
      <div className="divide-gray-20 divide-y">
        <ExhibitionList />
      </div>
    </div>
  );
}
