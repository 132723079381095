import { useQueryClient, useMutation } from "@tanstack/react-query";
import ExhibitionService from "../service/ExhibitionService";
import { useNotification } from "../context/NotificationContext";

export function useEditExhibition() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: editExhibition, isLoading: isEditing } = useMutation({
    mutationFn: async (exhibitionData) => {
      return await ExhibitionService.update(exhibitionData); // Ritorna i dati aggiornati
    },

    onSuccess: async (updatedExhibition) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData =
        JSON.parse(localStorage.getItem("exhibitionsData")) || [];
      const updatedData = savedData?.map((school) =>
        school.id === updatedExhibition.id ? updatedExhibition : school,
      );
      localStorage.setItem("exhibitionsData", JSON.stringify(updatedData));
      addNotification("Eshibition updated", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["exhibitions"]);
    },
    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error editing exhibition:", error);
    },
  });

  return { editExhibition, isEditing };
}

/* const updateWorkData = await WorkService.update(data, id);
updateWork(updateWorkData);



function updateWork(updatedWorkData) {
    const index = data.findIndex(
      (artwork) => artwork.id === updatedWorkData.id
    );
    // Crea una copia dei dati correnti
    const newData = [...data];
    // Aggiorna l'opera d'arte nei dati
    newData[index] = updatedWorkData;
    // Aggiorna lo stato dei dati
    //setData(newData);
    localStorage.removeItem(`searchData`);
    localStorage.setItem(`searchData`, JSON.stringify(newData));


 */
