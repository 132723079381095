// NotificationContext.js
import React, { createContext, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid"; // Usare un generatore di ID univoci

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = uuidv4(); // Genera un ID univoco per la notifica
    setNotifications((prev) => [...prev, { id, message, type }]);

    // Timeout per rimuovere la notifica dopo 3 secondi
    setTimeout(() => {
      removeNotification(id);
    }, 3000);
  };

  const removeNotification = (id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id),
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification deve essere utilizzato all'interno di NotificationProvider",
    );
  }
  return context;
};
