import { useQuery } from "@tanstack/react-query";
import WorkService from "../service/WorkService";
import { useWorksData } from "./useWorksData";

export function useSingleWork(workId) {
  const { works } = useWorksData();

  const fetchWork = async () => {
    // Se siamo offline, recupera il dato da localStorage
    if (!navigator.onLine) {
      const cachedWorks = JSON.parse(localStorage.getItem("worksData")) || [];
      console.log(cachedWorks);
      const cachedWork = cachedWorks.find((work) => work.id === workId);
      return cachedWork;
    } else {
      // Altrimenti, recupera il dato dal server
      return WorkService.get(workId);
    }
  };

  const {
    data: item,
    isLoading,
    error,
  } = useQuery(
    ["work", workId],
    fetchWork,

    {
      networkMode: "always", // Assicuriamoci che la query venga eseguita se abbiamo un workId

      cacheTime: 1000 * 60 * 60, // Cache per 1 ora
      staleTime: 1000 * 60 * 5,
      onError: () => {
        // Se la fetch non va a buon fine, recupera il dato dall'insieme dei dati in cache
        const cachedWork = works.find((work) => work.id === workId);
        return cachedWork;
      },
    },
  );
  return {
    item,
    isLoading,
    error,
  };
}
