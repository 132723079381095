import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_controller_0: "Server Error",
  nam_auth_6: "Token Expired",
  default: "Generic Error",
};

const ExhibitionService = {
  getAll: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/exhibitions/gets",
      });

      return resp.exhibitions;
    } catch (error) {
      throw new Error(
        "Fetching Exhibitions failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  create: async (exhibitionData) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/exhibitions/create",
        data: exhibitionData,
      });

      return resp.exhibition;
    } catch (error) {
      throw new Error(
        "Creating exhibition failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
  delete: async (exhibitionId) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/exhibitions/delete",
        data: {
          request: exhibitionId,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Deleting exhibition failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },

  update: async (exhibitionData) => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "post",
        url: "/exhibitions/update",
        data: exhibitionData,
      });
      return resp.exhibition;
    } catch (error) {
      throw new Error(
        "Update exhibition failed: " +
          (ERROR_MESSAGES[error.errorCode] || ERROR_MESSAGES.default),
      );
    }
  },
};

export default ExhibitionService;
