import React from "react";
import LoadingSpinner from "./LoadingSpinner";

const Button = ({ onClick, label, loading, disabled }) => (
  <button
    type="submit"
    onClick={onClick}
    disabled={disabled}
    className={`m-auto mt-6 flex w-6/12 items-center justify-center gap-2 rounded-md bg-slate-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 ${disabled ? "cursor-not-allowed text-opacity-50 opacity-50" : "hover:bg-slate-400"} `}
  >
    {loading && <LoadingSpinner />}
    {label}
  </button>
);

export default Button;
