// src/data/useCreateWork.js
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ArtistService from "../service/ArtistService";
import { useNotification } from "../context/NotificationContext";

export function useCreateArtist() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: createArtist, isLoading: isCreating } = useMutation({
    mutationFn: async (newArtist) => {
      try {
        const response = await ArtistService.create(newArtist);
        console.log("API Response:", response); // Log della risposta dell'API
        return response; // Assicurati di restituire la risposta
      } catch (error) {
        throw error; // Rilancia l'errore per la gestione
      }
    },
    onSuccess: async (data) => {
      //Aggiorna i dati restuiti dal server nel localStorage locale
      const savedData = JSON.parse(localStorage.getItem("artistsData")) || [];
      const updatedData = [...savedData, data];

      const updatedSortedData = [...updatedData].sort((a, b) =>
        a.name.localeCompare(b.name),
      );

      localStorage.setItem("artistsData", JSON.stringify(updatedSortedData));
      addNotification("Artist Created", "success");
      // Invalida e refetch dei dati
      queryClient.invalidateQueries(["artists"]);
    },

    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error adding artist:", error);
    },
  });

  return { isCreating, createArtist };
}
