// ToastNotifications.js
import React from "react";
import { useNotification } from "../context/NotificationContext";

const ToastNotifications = () => {
  const { notifications, removeNotification } = useNotification();

  return (
    <div className="fixed right-1/2 top-20 z-50 translate-x-1/2 p-4">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`mb-2 rounded bg-opacity-80 px-6 py-2 text-white ${
            notification.type === "success"
              ? "bg-green-500"
              : notification.type === "error"
                ? "bg-red-500"
                : "bg-slate-600"
          }`}
        >
          {notification.message}
          <button
            onClick={() => removeNotification(notification.id)} // Usa removeNotification per eliminare
            className="ml-4 text-lg font-bold text-white"
          >
            &times; {/* La "X" per chiudere */}
          </button>
        </div>
      ))}
    </div>
  );
};

export default ToastNotifications;
