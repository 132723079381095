import React, { useEffect, useRef, useState } from "react";
import { useDropdown } from "../../hooks/useDropdown";

const Dropdown = ({ getData, label, name, value, onChange, placeholder }) => {
  const { filteredData, fetchData } = useDropdown({ getData, value });

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  // Fetch delle opzioni all'inizio
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  //Chiusura delle opzioni al clic fuori dal menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        if (inputRef.current) {
          inputRef.current.blur(); // Perde il focus quando si clicca fuori
        } // Chiude il dropdown se il clic avviene fuori
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setIsOpen]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange({
      target: {
        name,
        value: newValue,
      },
    });
    setIsOpen(true);
  };

  const handleSelect = (item) => {
    const selectedValue = item?.name || item?.description || String(item);
    onChange({
      target: {
        name,
        value: selectedValue,
      },
    });
    setIsOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setIsOpen(false);
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <label className="block w-1/2">{label}</label>
      <div className="block w-1/2">
        <input
          id={name}
          name={name}
          value={value}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          ref={inputRef}
          placeholder={placeholder}
          className="w-full rounded border border-gray-300 p-1"
        />
        {isOpen && (
          <ul
            ref={dropdownRef}
            className="absolute top-9 z-10 max-h-40 w-1/2 overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg"
          >
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <li
                  key={item?.id || item}
                  className="cursor-pointer p-2 hover:bg-gray-200"
                  onClick={() => handleSelect(item)}
                >
                  {item?.name || item?.description || String(item)}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-700">No results found</li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

export default Dropdown;
