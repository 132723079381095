import React, { useState } from "react";
import { IoClose } from "react-icons/io5"; // Importa l'icona
import FormInput from "./UI/FormInput";
import Button from "./UI/Button";
import AuthService from "../service/AuthService";

const PasswordRecovery = ({
  email,
  setEmail,
  onPasswordReset,
  onBackToLogin,
}) => {
  const [recoveryStep, setRecoveryStep] = useState(0);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Stato per la password confermata
  const [passwordError, setPasswordError] = useState(""); // Stato per l'errore sulle password
  const [loading, setLoading] = useState(false); // Stato per gestire il caricamento

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (recoveryStep === 0) {
        await AuthService.rescuePassword(email);
        setLoading(false);
        setRecoveryStep(1);
      } else if (recoveryStep === 1) {
        await AuthService.confirmRescuePassword(
          email,
          confirmationCode,
          newPassword,
        );
        onPasswordReset();
      }
    } catch (error) {
      // Gestisci errore
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (newPassword && e.target.value !== newPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError(""); // Rimuovi l'errore se le password corrispondono
    }
  };

  return (
    <div className="relative">
      <IoClose
        className="top- absolute right-0 cursor-pointer text-xl text-gray-700 hover:text-gray-900"
        onClick={onBackToLogin}
      />
      <form onSubmit={handleSubmit}>
        {recoveryStep === 0 && (
          <>
            <FormInput
              label="Enter your email to reset password"
              id="recovery-email"
              name="recovery-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />
            <Button
              label={loading ? "Submitting..." : "Submit"}
              loading={loading}
            />
          </>
        )}
        {recoveryStep === 1 && (
          <>
            <FormInput
              label="Enter the confirmation code"
              id="confirmation-code"
              name="confirmation-code"
              type="text"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            <FormInput
              label="Enter your new password"
              id="new-password"
              name="new-password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="new-password"
            />

            <FormInput
              label="Confirm your new password"
              id="confirm-password"
              name="confirm-password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="new-password"
            />
            {passwordError && (
              <div className="text-sm text-red-500">{passwordError}</div>
            )}

            <Button
              label={loading ? "Submitting..." : "Submit"}
              loading={loading}
              disabled={passwordError || !newPassword || !confirmPassword}
            />
          </>
        )}
      </form>
    </div>
  );
};

export default PasswordRecovery;
