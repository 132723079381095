import { useQueryClient, useMutation } from "@tanstack/react-query";
import WorkService from "../service/WorkService";
import { useNotification } from "../context/NotificationContext";

export function useDeleteWork(workId) {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: deleteWork, isLoading: isDeleting } = useMutation(
    async () => {
      await WorkService.delete(workId);
    },
    {
      onSuccess: () => {
        console.log("Work deleted successfully");

        // Aggiorna localStorage rimuovendo il work appena eliminato
        const savedData = JSON.parse(localStorage.getItem("searchData")) || [];
        const updatedData = savedData?.filter(
          (element) => element.id !== workId,
        );
        localStorage.setItem("searchData", JSON.stringify(updatedData));
        addNotification("Work deleted", "success");
        // Invalida e refetch dei dati
        queryClient.invalidateQueries(["worksData"]);
        // Aggiorna lo stato dei dati locali immediatamente
        queryClient.setQueryData(["worksData"], updatedData);
      },
      onError: (error) => {
        // Gestisci l'errore
        addNotification(error.message, "error");
        console.error("Error deleting work:", error);
      },
    },
  );

  return {
    deleteWork,
    isDeleting,
  };
}
