import { useQueryClient, useMutation } from "@tanstack/react-query";
import AuthService from "../service/AuthService";
import { useNotification } from "../context/NotificationContext";

export function useEditRoles() {
  const queryClient = useQueryClient();
  const { addNotification } = useNotification();

  const { mutate: editRoles, isLoading: isEditing } = useMutation({
    mutationFn: async ({ userId, updatedRoles }) => {
      console.log(updatedRoles);
      return await AuthService.setRoles(userId, updatedRoles);
    },

    onSuccess: () => {
      addNotification("User roles updated", "success");
      queryClient.invalidateQueries("users");
    },

    onError: (error) => {
      // Gestisci l'errore
      addNotification(error.message, "error");
      console.error("Error editing roles:", error);
    },
  });

  return { editRoles, isEditing };
}
