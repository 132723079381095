import Modal from "react-modal";
import { useDeleteWork } from "../data/useDeleteWork";
import { useNavigate, useParams } from "react-router-dom";

export default function DeleteWorkModal({ isOpen, onRequestClose }) {
  const navigate = useNavigate();
  const { id } = useParams(); // Estrae l'ID dall'URL
  const { deleteWork, isDeleting } = useDeleteWork(id); // Usa il hook per la mutazione

  const handleDeleteSubmit = () => {
    deleteWork(id, { onSuccess: () => navigate("/search") }); // Invoca la mutazione per creare il nuovo lavoro
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          width: "300px",
          height: "120px",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          overflow: "auto",
          padding: "14px",
          borderRadius: "8px",
          border: "1px solid #ccc",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <div className="my-4 w-full text-center font-semibold">Are You Sure?</div>
      <div className="flex justify-center gap-4">
        <button
          onClick={handleDeleteSubmit}
          className="relative w-12 rounded border border-gray-300 bg-slate-100 p-1 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-700 disabled:text-white disabled:opacity-50"
        >
          Yes
        </button>
        <button
          onClick={onRequestClose}
          className="relative w-12 rounded border border-gray-300 bg-slate-100 p-1 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-700 disabled:text-white disabled:opacity-50"
        >
          No
        </button>
      </div>
    </Modal>
  );
}
