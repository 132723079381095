// AuthService.js
import makeAPIRequest from "./utils/APIUtils";

const ERROR_MESSAGES = {
  nam_auth_5: "Wrong Credentials",
  nam_auth_4: "Forbidden for your role",
  nam_auth_2: "Email already exists",
  nam_auth_1: "Parameters Not Valid",
  nam_auth_0: "Generic Error",
  nam_controller_0: "Server Error",
  default: "Generic Error",
};

const AuthService = {
  login: async (email, password) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/login",
        data: {
          email,
          password,
        },
      });

      const { token, name, lastname } = resp.response;

      localStorage.setItem("token", token);
      localStorage.setItem("name", name);
      localStorage.setItem("lastname", lastname);
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    } catch (error) {
      console.log(error);
      throw new Error(
        "Login failed: " + (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
  refreshToken: async () => {
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");

    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/login",
        data: {
          email,
          password,
        },
      });
      const token = resp.response.token;
      localStorage.setItem("token", token);
    } catch (error) {
      console.log(error);
      throw new Error("Error refreshing token. Please logout");
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("lastname");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    //onLogout();
  },

  getActiveUsers: async () => {
    try {
      //const token = localStorage.getItem("token");
      const resp = await makeAPIRequest({
        method: "get",
        url: "/auth/getsActive",
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Fetching Active Users failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },

  getRoles: async () => {
    try {
      const resp = await makeAPIRequest({
        method: "get",
        url: "/auth/getsRole",
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Fetching Active Users Artist failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },

  createUserWithRole: async (userData) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/createWithRole",
        data: { ...userData, password: "P4ssw0rd!" },
      });

      return resp.response;
    } catch (error) {
      console.log(error);
      throw new Error(
        "Creating new user failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
  setRoles: async (userId, rolesToEnable) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/setRoles",
        data: {
          userId,
          rolesToEnable,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Setting new roles failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
  deleteUser: async (userId) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/delete",
        data: {
          request: userId,
        },
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Setting new roles failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
  rescuePassword: async (email) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/rescuePassword",
        data: {
          request: email,
        },
      });
      return resp.response;
    } catch (error) {
      throw new Error(
        "Rescue password failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
  confirmRescuePassword: async (email, confirmationCode, newPassword) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/confirmRescuePassword",
        data: {
          email: email,
          temporaryCode: confirmationCode,
          newPassword: newPassword,
        },
      });

      return resp.response;
    } catch (error) {
      throw new Error(
        "Change password failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },

  resetPassword: async (userId) => {
    try {
      const resp = await makeAPIRequest({
        method: "post",
        url: "/auth/changePasswordOther",
        data: {
          userId: userId,
          newPassword: "P4ssw0rd!",
        },
      });
      return resp;
    } catch (error) {
      console.log(error);
      throw new Error(
        "Reset password failed: " +
          (ERROR_MESSAGES[error.errorCode] || error.message),
      );
    }
  },
};

export default AuthService;
