import {
  ArrowLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import { useFilter } from "../data/useFilter";
import { useNavigationSearch } from "../hooks/useNavigationSearch";

export default function NavigationSearch({ id }) {
  const { filteredData } = useFilter();
  const {
    searchFlow,
    searchEnd,
    searchStart,
    handleClickBack,
    handleClickSearchPrev,
    handleClickSearchNext,
    handleClickPrevSearchedItem,
  } = useNavigationSearch(id, filteredData);

  return (
    <>
      <div
        className="absolute left-6 top-24 flex cursor-pointer items-center space-x-2 text-lg font-semibold text-stone-900"
        onClick={handleClickBack}
      >
        <ArrowLeftIcon className="h-5 w-5"></ArrowLeftIcon>
        <span>Back</span>
      </div>

      <div className="flex justify-center gap-4">
        {searchFlow && (
          <div className="flex gap-4">
            {!searchStart && (
              <button
                onClick={handleClickSearchPrev}
                className="flex cursor-pointer items-center gap-2 rounded border border-neutral-600 bg-slate-50 px-4 py-1"
              >
                <ChevronDoubleLeftIcon className="h-5 w-5"></ChevronDoubleLeftIcon>
                <span>Prev</span>
              </button>
            )}
            {!searchEnd && (
              <div onClick={handleClickSearchNext} className="flex gap-4">
                <button className="flex cursor-pointer items-center gap-2 rounded border border-neutral-600 bg-slate-50 px-4 py-1">
                  <span>Next</span>
                  <ChevronDoubleRightIcon className="h-5 w-5"></ChevronDoubleRightIcon>
                </button>
              </div>
            )}
          </div>
        )}

        {!searchFlow && (
          <div
            className="flex cursor-pointer items-center gap-2 rounded border border-neutral-600 bg-slate-50 px-4 py-1"
            onClick={handleClickPrevSearchedItem}
          >
            Resume Research
          </div>
        )}
      </div>
    </>
  );
}
