import React from "react";
import { Controller, useForm } from "react-hook-form";

const UserForm = ({ defaultValues, onSubmit, onCancel, fields, roles }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-grow">
      {fields
        .filter((field) => field.name !== "roles")
        .map((field) => (
          <div className="mb-2 flex flex-col" key={field.name}>
            <label className="mb-1">{field.label}</label>
            <input
              {...register(field.name, { required: field.required })}
              className="rounded border border-gray-300 p-2"
            />
            {errors[field.name] && (
              <span className="text-red-500">{field.label} is required</span>
            )}
          </div>
        ))}
      <div className="mb-2 flex flex-col">
        <label className="mb-1">Role</label>
        <Controller
          name="role"
          control={control}
          defaultValue={roles[0]} // Aggiungi defaultValue
          render={({ field }) => (
            <div className="relative">
              <select
                {...field}
                className="block w-full appearance-none rounded border border-gray-300 bg-white px-3 py-2 pr-8"
              >
                {roles.map((role, id) => (
                  <option key={id} value={role}>
                    {`${role.charAt(0).toUpperCase() + role.slice(1)}`}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
          )}
        />
      </div>

      <div className="flex space-x-2">
        <button
          type="submit"
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-700"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default UserForm;
