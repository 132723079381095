import { useQuery } from "@tanstack/react-query";
import ImagesService from "../service/ImagesService";

export function useImage(imageId) {
  const {
    data: image,
    isLoading,
    error,
  } = useQuery(
    ["images", imageId],
    async () => {
      if (!imageId) {
        return "";
      }
      const image = await ImagesService.getJpg(imageId);
      if (!image) {
        return "";
      }
      return URL.createObjectURL(image);
    },
    {
      cacheTime: 1000 * 60 * 60, // Cache per 1 ora
      staleTime: 5,
      refetchOnWindowFocus: true, // Ritenta automaticamente il fetch quando la finestra viene messa a fuoco

      onError: (err) => {
        console.error(
          "Errore catturato dalla configurazione di useQuery:",
          err,
        );
      }, // Considera i dati "stale" dopo 5 minuti
    },
  );

  return {
    image: image || "",
    isLoading,
    error,
  };
}
