// src/components/lists/SchoolList.js
import React from "react";
import GenericList from "./GenericList";

import { useExhibitions } from "../../data/useExhibitions";
import { useCreateExhibition } from "../../data/useCreateExhibition";
import { useEditExhibition } from "../../data/useEditExhibition";
import { useDeleteExhibition } from "../../data/useDeleteExhibition";

const exhibitionFields = [
  { name: "title", label: "Title", required: true },
  { name: "description", label: "Description", required: false },
  { name: "abbreviation", label: "Abbreviation", required: true },
];

const ExhibitionsList = () => {
  const { exhibitions, error, isLoading } = useExhibitions();
  const { editExhibition, isEditing } = useEditExhibition();
  const { deleteExhibition, isDeleting } = useDeleteExhibition();
  const { createExhibition, isCreating } = useCreateExhibition();

  return (
    <GenericList
      items={exhibitions}
      itemName="Exhibition"
      isLoading={isLoading || isDeleting}
      error={error}
      onEdit={(updatedItem) => editExhibition(updatedItem)}
      onDelete={(id) => deleteExhibition(id)}
      onCreate={(newItem) => createExhibition(newItem)}
      fields={exhibitionFields}
    />
  );
};

export default ExhibitionsList;
