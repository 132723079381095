import React from "react";
import { useForm } from "react-hook-form";

const ItemForm = ({ defaultValues, onSubmit, onCancel, fields }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-grow">
      {fields.map((field) => (
        <div className="mb-2 flex flex-col" key={field.name}>
          <label className="mb-1">{field.label}</label>
          <input
            {...register(field.name, { required: field.required })}
            className="rounded border border-gray-300 p-2"
          />
          {errors[field.name] && (
            <span className="text-red-500">{field.label} is required</span>
          )}
        </div>
      ))}
      <div className="flex space-x-2">
        <button
          type="submit"
          className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-700"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-700"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default ItemForm;
