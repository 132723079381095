import React, { useRef } from "react";
import { useImageUpload } from "../hooks/useImageUpload";

export function ImageUploadForm({ workId }) {
  const fileInputRef = useRef(null);
  const {
    preview,
    handleFileUpload,
    onUploadClick,
    isUploading,
    error,
    resetFileInput,
  } = useImageUpload(workId);

  const handleUploadAndReset = async () => {
    await onUploadClick();
    resetFileInput();
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div className="flex flex-col items-center">
      {!preview && (
        <>
          <input
            type="file"
            accept="image/*"
            disabled={isUploading}
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <button
            onClick={() => fileInputRef.current.click()}
            disabled={isUploading}
            className={`mt-4 rounded border border-gray-300 px-4 py-1 font-semibold ${
              isUploading
                ? "cursor-not-allowed bg-gray-300"
                : "bg-slate-100 hover:bg-slate-500 hover:text-white active:bg-slate-700"
            }`}
          >
            {isUploading ? "Uploading..." : "Upload image"}
          </button>
        </>
      )}
      {error && <p className="mt-2 text-red-500">{error}</p>}
      {preview && (
        <div className="mt-4 text-center">
          <h3 className="mb-2 text-lg font-medium">Image Preview:</h3>
          <img
            src={preview}
            alt="Anteprima"
            className="mx-auto h-24 w-24 rounded border-2 border-gray-300 object-cover"
          />
          <button
            type="button"
            onClick={resetFileInput}
            className="mx-2 mt-4 rounded bg-red-500 px-4 py-2 text-white"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleUploadAndReset}
            className={`mt-4 rounded px-4 py-2 text-white ${
              isUploading
                ? "cursor-not-allowed bg-gray-300"
                : "bg-green-500 hover:bg-green-700"
            }`}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
        </div>
      )}
    </div>
  );
}
