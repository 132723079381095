// src/components/lists/SchoolList.js
import React from "react";
import GenericList from "./GenericList";

import { useEditSchool } from "../../data/useEditSchool";
import { useDeleteSchool } from "../../data/useDeleteSchool";
import { useSchools } from "../../data/useSchools";
import { useCreateSchool } from "../../data/useCreateSchool";

const schoolFields = [{ name: "description", label: "Name", required: true }];

const SchoolsList = () => {
  const { schools, error, isLoading } = useSchools();
  const { editSchool, isEditing } = useEditSchool();
  const { deleteSchool, isDeleting } = useDeleteSchool();
  const { isCreating, createSchool } = useCreateSchool();

  return (
    <GenericList
      items={schools}
      itemName="School"
      isLoading={isLoading || isDeleting}
      error={error}
      onEdit={(updatedItem) => editSchool(updatedItem)}
      onDelete={(id) => deleteSchool(id)}
      onCreate={(newItem) => createSchool(newItem)}
      fields={schoolFields}
    />
  );
};

export default SchoolsList;
