import AuthService from "../service/AuthService";
import { Link, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";

const handleLogoutClick = (onLogout) => {
  AuthService.logout();
  onLogout();
};

export default function Header({ onLogout }) {
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const isActive = (path) => location.pathname === path;

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Chiude il menu se si clicca fuori da esso
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed top-0 z-10 flex min-h-16 w-full flex-wrap items-center justify-between bg-white py-2 pl-2 pr-0 text-neutral-800 drop-shadow-xl md:pl-8">
      <Link to="/">
        <div className="text-center text-xl font-semibold">NPA</div>
      </Link>
      <div className="flex">
        <Link to="/search">
          <div
            className={`rounded-md rounded-r-none border px-1 py-1 text-center text-base font-medium md:px-4 ${
              isActive("/search")
                ? "border-slate-500 bg-slate-500 text-white"
                : "border-r-0"
            }`}
          >
            Search
          </div>
        </Link>
        <Link to="/exhibitions">
          <div
            className={`border px-4 py-1 text-center text-base font-medium ${
              isActive("/exhibitions")
                ? "border-slate-500 bg-slate-500 text-white"
                : "border-r-0"
            }`}
          >
            Exhibitions
          </div>
        </Link>
        <Link to="/artists">
          <div
            className={`border px-1 py-1 text-center text-base font-medium md:px-4 ${
              isActive("/artists")
                ? "border-slate-500 bg-slate-500 text-white"
                : "border-r-0"
            }`}
          >
            Artists
          </div>
        </Link>
        <Link to="/schools">
          <div
            className={`rounded-md rounded-l-none border px-1 py-1 text-center text-base font-medium md:px-4 ${
              isActive("/schools")
                ? "border-slate-500 bg-slate-500 text-white"
                : ""
            }`}
          >
            Schools
          </div>
        </Link>
      </div>

      <div className="relative leading-none" ref={menuRef}>
        <button className="mr-8">
          <FaUserCircle
            size={30}
            className="cursor-pointer"
            onClick={toggleMenu}
          />
        </button>
        {isMenuOpen && (
          <div className="absolute right-2 top-10 w-48 rounded-lg border bg-white shadow-lg">
            <ul className="py-2">
              <li className="">
                <Link
                  className="block h-full w-full cursor-pointer px-4 py-2 text-gray-800 hover:bg-gray-100"
                  to="/users"
                  onClick={() => toggleMenu()}
                >
                  Users Profiles
                </Link>
              </li>
              <li
                className="cursor-pointer px-4 py-2 text-gray-800 hover:bg-gray-100"
                onClick={() => handleLogoutClick(onLogout)}
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
}
